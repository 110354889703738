<template>
  <div class="md-layout text-center">
    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
      <login-card header-color="rose">
        <h4
          slot="title"
          class="title"
        >
          Verify new Account
        </h4>

        <md-field
          slot="inputs"
          class="md-form-group"
        >
          <md-icon>lock_outline</md-icon>
          <label>Password...</label>
          <md-input
            v-model="verification.new_password"
            type="password"
            @keyup.enter="onLogin"
          />
        </md-field>

        <md-field
          slot="inputs"
          class="md-form-group"
        >
          <md-icon>lock_outline</md-icon>
          <label>Confirm Password...</label>
          <md-input
            v-model="verification.new_password_confirmation"
            type="password"
            @keyup.enter="onLogin"
          />
        </md-field>
        <md-button
          slot="footer"
          class="md-simple md-success md-lg md-rose"
          @click="onLogin"
        >
          Verify
        </md-button>
      </login-card>
    </div>
  </div>
</template>
<script>
import { LoginCard } from '@/components';

export default {
  components: {
    LoginCard,
  },
  data() {
    return {
      firstname: null,
      verification: {
        new_password: null,
        new_password_confirmation: null,
      },
    };
  },
  mounted() {
  },
  methods: {
    onLogin() {
      if (!this.verification.new_password) {
        this.fireError('Please send a new password');
        return;
      }
      if (this.verification.new_password !== this.verification.new_password_confirmation) {
        this.fireError('Passwords are not equal');
        return;
      }
      this.request(`${this.$API.USERS + this.$route.params.verification_code}/verify`, 'post', this.verification, (data) => {
        this.fireSuccess(data);

        this.$router.push({ name: 'Login' });
      }, {}, true);
    },
  },
};
</script>

<style></style>
